import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  createBrowserRouter,
  Link,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
import logo from './images/logo.png'
import './App.scss';
import Icon from './Icon';
import newLogo from './newlogo.png';
import ryl_logo from './images/image.png'
import fist from './images/fist-poster.png'
import tj from './images/image05.jpg'
import HowCanIHelp from './Join';
import Plot from 'react-plotly.js';
import { getStats } from './services';
import Home from './Home';
import About from './About';
import TenantOrg from './TenantOrg';
import Art from './Art';
import instagram from './images/instagram-png-instagram-icon-1600-2611987004.png'
import email from './images/email-icon-png-email-icon-2048-633966439.png'
import FAQ from './FAQ';
import TenPoint from './TenPoint';
import Popup from 'reactjs-popup';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
]);

interface Node {
  root?: true
  text: string
  choices?: Node[]
  to?: number
}

const nodes: Node[] = [

  {
    root: true,
    text: "I want to join!",
    to: 2
  },

  {
    root: true,
    text: "I need help with housing",
    to: 3
  },

  {
    text: "What are you good at?",
    choices: [
      {
        text: "Tech"
      },
      {
        text: "Organizing"
      },
      {
        text: "Eviction Law"
      },
      {
        text: "Talking to your neighbors"
      }
    ]
  }

]

interface ReviewDatum {
  count: number
  date: string
}

function App() {

  const [atBottom, setAtBottom]: [boolean, any] = useState(true)
  const [hciHelp, setShowHciHelp] = useState(true)
  const [reviewData, setReviewData] = useState(null as ReviewDatum[] | null)
  const join = useRef(null);
  const location = useLocation();
  const emailPopup = useRef(null);
  useEffect(() => {
    // getStats().then((stats: any) => {
    //   console.log(stats)
    //   setReviewData(stats.reviews.map((rev: any, i: number) => ({
    //     count: stats.reviewCount - i,
    //     date: rev.date_added
    //   })))
    // })
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      console.log('entry', entry);
      console.log('entry.isIntersecting', entry.isIntersecting);
      //setAtBottom(entry.isIntersecting)
      setAtBottom(window.scrollY > 0)
    });
    if (join && join.current) {
      observer.observe(join.current!)
    }
  });

  function scrollToJoin() {
    if (join && join.current) {
      (join.current as HTMLElement).scrollIntoView()
      setAtBottom(true)
    }
  }

  useEffect(() => {
    console.log(location)
  }, [location])

  return (

    <div className="App">
      <Popup
            ref={emailPopup}
            position="right center">
                <div className='modal email-modal'>
                  <p>Email us at <a href="mailto:rentersactionnetworkpdx@gmail.com">rentersactionnetworkpdx@gmail.com</a></p>
                </div>

        </Popup>
      <header className="app-header">
        <div className='row'>
          <Link to="/" className='logo'>
            <img src={logo}></img>

          </Link>
          <div className='navbar-options'>
            {
              location.pathname !== '/faq' &&
              <a href="/faq" className='option'>FAQs</a>
            }
            {
              location.pathname !== '/about' &&
              <a href="/about" className='option'>About Us</a>
            }
            {
              location.pathname !== '/tenant-organizing' &&
              <a href="/tenant-organizing" className='option'>Tenant Organizing</a>
            }
            {
              location.pathname !== '/art' &&
              <a href="/propaganda" className='option'>Propaganda</a>
            }
            {
              location.pathname !== '/10pt' &&
              <a href="/10pt" className='option'>Ten Point Program</a>
            }
          </div>
        </div>
        {/* <img className='flex-child logo' src={newLogo} alt="logo"/> */}
        {/* <Icon /> */}

        {/* <div>Renters Action Network PDX</div> */}
        {/* <div></div> */}

        {/* <div className='flex-child logo-text'>
          <div className='renters'>RENTERS</div> 
          <div className='action'>ACTION</div>
          <div>NETWORK</div>
        </div> */}
       <div className="contact-options">
          <a href="https://instagram.com/rentersactionnetworkpdx" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram"></img>
          </a>
          <button className='email' onClick={() => (emailPopup.current as any).open()}>
            <img src={email} alt="email"></img>
          </button>
        </div>
      </header>
      {/* <img className="fist" src={fist}/> */}


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="about" element={<About />} />
        <Route path="tenant-organizing" element={<TenantOrg />} />
        <Route path="propaganda" element={<Art />} />
        <Route path="10pt" element={<TenPoint />} />
      </Routes>

      <img></img>

        {/* <button className="VOTE"
          onClick={() => {
            window.open('https://tj4housing.com/');
          }}>
          <img alt="TJ Noddings for Multanomah County Commissioner District 3" src={tj} />
          <div className='lets'>Stand against Landlord Greed</div>
          <span className='vote'> Vote TJ</span>
          <span className='vote-date'> on May 21st</span>
        </button> */}







      {/* <div className='help-box'>
        <div className='help'>
          {
            !hciHelp &&
            <button
              className={`${hciHelp ? 'selected' : ''}`}
              onClick={() => setShowHciHelp(!hciHelp)}>
              How can I help?
            </button>
          }
        </div>
        {
          hciHelp &&
          <HowCanIHelp></HowCanIHelp>
        }
      </div> */}



{/* 
      <div ref={join}>
      </div> */}

      {/* <button>Contact Us</button> */}

      {/* <div className='jump-to-bottom-wrapper'>
        <button className={atBottom ? 'hide-jump' : 'show-jump'} onClick={() => scrollToJoin()}>JOIN US</button>
      </div> */}
    </div>

  );
}

export default App;
