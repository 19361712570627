import Join from "./Join";
import picnic from './images/picnic.png'
import artshow from './images/art_show.png'

function Home() {
  return <div>
    <h1>What we do</h1>
    <div className='actions-container'>
      {/* <div className='projects'>Our </div> */}
      <div className='action-item-list'>
        <a className='item rate'
          onClick={() => {
            window.open('https://rateyourlandlordpdx.com/');
          }}>
          {/* <img alt="Rate Your Landlord Map" src={ryl_logo} /> */}
          RateYourLandlordPDX.com
          <p>Figure out who owns your building, review them, and get connected with your neighbors</p>
        </a>
        <a className='item dto' href="/tenant-organizing">
          Forming Tenant Unions
          <p>forming <strong>Tenant Unions</strong> to build renter power</p>
        </a>
        <a className='item prop-item' href="/propaganda">
          Propaganda
          <p>Changing the narrative with housing justice art</p>
        </a>
      </div>
    </div>
    <div className="goals">
      {/* <h2>Our Goals</h2>
      <ul>
        <li>Build Tenant Power</li>
        <li>Lower the Rent</li>
        <li>Prevent Evictions</li>
        <li>Protect Renters Rights</li>
        <li>Housing for all</li>
      </ul> */}
    </div>
    <Join></Join>
  </div>
}

export default Home;